import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import DateTimePicker from '@/components/controls/DateTimePicker'
import { FieldValidator, notEmpty, ValidationGroup } from '@/helpers/validation'
import { BButton } from '../bootstrap'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'

@Component
export default class SalaryReport extends tsx.Component<{}> {
  validator = new ValidationGroup()

  startDate = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.validator,
  })

  endDate = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.validator,
  })

  pending = false

  async download() {
    if (!this.validator.validate()) {
      showError('Заполните даты')
      return
    }

    this.pending = true

    try {
      const response = await axios.get<Blob>(
        `v1/approved-homeworks?from=${this.startDate.value}&to=${this.endDate.value}`,
        { responseType: 'blob' },
      )

      const href = URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `${this.startDate.value} ${this.endDate.value}.xlsx`,
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      console.error(error)
      showError('При скачивании отчета произошла ошибка')
    }

    this.pending = false
  }

  protected render() {
    return (
      <div class="mt-50 mx-50">
        <label>Скачать отчет по проверенным дз</label>
        <div class="d-flex">
          <DateTimePicker
            value={this.startDate.value}
            onInput={this.startDate.set}
            displayedFormat="l d M Y"
            placeholder="Дата начала"
          />
          <DateTimePicker
            value={this.endDate.value}
            onInput={this.endDate.set}
            displayedFormat="l d M Y"
            placeholder="Дата окончания"
            class="mx-50"
          />
          <BButton
            variant="primary"
            onClick={this.download}
            disabled={this.pending}
          >
            Скачать
          </BButton>
        </div>
        <hr />
      </div>
    )
  }
}
