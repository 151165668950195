<template>
  <h3 class="mb-2">
    <span v-for="(period, index) of periods" :key="index">
      <BBadge :id="`badge-${index}`" variant="light-primary">
        {{ period.value }}
        <BPopover
          :target="`badge-${index}`"
          triggers="hover"
          placement="bottom"
        >
          <div>
            {{ `${period.dateStart} - ${period.dateEnd}` }}
          </div>
          <div class="text-nowrap">
            {{ `Принято ДЗ: ${period.homeworksApproved}` }}
          </div>
          <div class="text-nowrap">
            {{ `Ставка за ДЗ: ${period.price}` }}
          </div>
          <div class="text-nowrap">
            {{ `Средняя скорость ответа: ${period.answerTime}` }}
          </div>
          <div class="text-nowrap">
            {{ `Норма скорости ответа: ${period.answerPlan}` }}
          </div>
          <div class="text-nowrap">
            {{ `Коэффициент скорости: ${period.coef}` }}
          </div>
        </BPopover>
      </BBadge>
      <span v-if="feedback || telegram || index !== periods.length - 1">+</span>
    </span>

    <span v-if="telegram">
      <BBadge id="telegram" variant="light-success">
        {{ telegram.value }}
        <BPopover target="telegram" triggers="hover" placement="bottom">
          <div class="text-nowrap">Бонус telegram</div>
          <div class="text-nowrap">
            {{ `Ставка: ${telegram.price}` }}
          </div>
          <div class="text-nowrap">
            {{ `Оценка telegram: ${telegram.rating}` }}
          </div>
          <div class="text-nowrap">
            {{ `Норма: ${telegram.plan}` }}
          </div>
          <div class="text-nowrap">
            {{ `Коэффициент telegram: ${telegram.coef}` }}
          </div>
        </BPopover>
      </BBadge>
      <span v-if="feedback">+</span>
    </span>
    <BBadge v-if="feedback" id="feedback" variant="light-warning">
      {{ feedback.value }}
      <BPopover target="feedback" triggers="hover" placement="bottom">
        <div class="text-nowrap">Бонус фидбэк</div>
        <div class="text-nowrap">
          {{ `Ставка: ${feedback.price}` }}
        </div>
        <div class="text-nowrap">
          {{ `Средняя оценка: ${feedback.rating}` }}
        </div>
        <div class="text-nowrap">
          {{ `Норма: ${feedback.plan}` }}
        </div>
        <div class="text-nowrap">
          {{ `Коэффициент фидбэк: ${feedback.coef}` }}
        </div>
      </BPopover>
    </BBadge>
    =
    <BBadge variant="light-info">{{ totalString }}</BBadge>
  </h3>
</template>

<script>
import { BBadge, BPopover } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BPopover,
  },
  props: {
    periods: { type: Array, required: true },
    telegram: { type: Object },
    feedback: { type: Object },
    totalString: { type: String, required: true },
  },
}
</script>
