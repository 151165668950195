






























































import StatsModal from '@/components/salary/StatsModal.vue'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import WayupTable from '@/components/WayupTable'
import { Column, Sort } from '@/components/WayupTable/types'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { Currency, IBonus, IPayment } from '@/store/salaryStore'
import { salaryStore } from '@/store'
import axios from '@/libs/axios'
import { showError } from '@/helpers/notifications'

@Component({ components: { WayupTable, UserCell, StatsModal } })
export default class SalaryPaymentsTable extends Vue {
  @Ref() readonly statsModal!: { show: (json: string) => void }
  @Ref() readonly paymentsTable!: WayupTable

  columns: Column<IPayment>[] = [
    {
      field: 'mentor',
      title: 'Ментор',
      sortable: { path: 'mentor.fullName' },
    },
    {
      field: 'amount',
      title: 'Выплачено зп',
      centered: true,
      sortable: true,
      formatter: (_, row) => this.formatPrice(row.amount, row.currency),
    },
    {
      field: 'bonuses',
      title: 'Выплачено бонусов',
      centered: true,
      sortable: {
        sortFn: (value1: IBonus[], value2: IBonus[]) => {
          const bonuses1 = value1.reduce((acc, b) => acc + b.amount, 0)
          const bonuses2 = value2.reduce((acc, b) => acc + b.amount, 0)
          return bonuses1 - bonuses2
        },
      },
    },
    {
      field: 'dateStart',
      title: 'За период',
      centered: true,
      formatter: (_, row) =>
        `${this.formatDate(row.dateStart)} - ${this.formatDate(row.dateEnd)}`,
    },
    {
      field: 'createdAt',
      title: 'Дата выплаты',
      centered: true,
      type: 'dateTime',
      sortable: true,
    },
    {
      field: 'homeworkPrice',
      title: 'Ставка за дз',
      centered: true,
      sortable: true,
      formatter: (_, row) => this.formatPrice(row.homeworkPrice, row.currency),
    },
    {
      field: 'paymentMethod',
      title: 'Способ оплаты',
      sortable: true,
      centered: true,
    },
    {
      field: 'stats',
      title: 'Статистика',
      centered: true,
    },
    {
      field: 'comment',
      title: 'Комментарий',
      formatter: value => value || '-',
    },
    {
      customField: 'buttons',
    },
  ]

  sort: Sort<IPayment> = {
    field: 'createdAt',
    dir: 'desc',
  }

  get payments() {
    return salaryStore.payments
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString('ru', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
    })
  }

  formatPrice(value: number, currency: Currency) {
    return `${value}${currency === 'rub' ? '₽' : '₴'}`
  }

  async deletePayment(payment: IPayment) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Вы действительно хотите удалить платеж наставнику ${
        payment.mentor.fullName
      } за период ${this.formatDate(payment.dateStart)} - ${this.formatDate(
        payment.dateEnd,
      )}?`,
      {
        title: 'Подтверждение удаления',
        centered: true,
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
      },
    )

    if (result) {
      try {
        await axios.delete(`v1/salary/payments/${payment.id}`)
        await salaryStore.fetchPayments()
      } catch (error) {
        console.error(error)
        showError('При удалении платежа произошла ошибка')
      }
    }
  }
}
