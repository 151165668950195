




















































import WayupSelect from '@/components/WayupSelect.vue'
import { Vue, Component } from 'vue-property-decorator'
import { ICourse } from '@/store/salaryStore'
import { salarySettingsStore } from '@/store'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import { salaryStore } from '@/store'

@Component({ components: { WayupSelect } })
export default class SalaryCalculationTable extends Vue {
  get courses() {
    return salaryStore.courses
  }

  get selectedCourse() {
    return salarySettingsStore.currentCourse
  }
  set selectedCourse(course: ICourse | null) {
    salarySettingsStore.setCurrentCourse(course)

    if (course) {
      saveToLS('salarySettingsCourse', course?.id)
    }
  }

  mounted() {
    const savedCourseId = loadFromLS<number>('salarySettingsCourse')
    if (savedCourseId) {
      this.selectedCourse =
        this.courses.find(c => c.id === savedCourseId) || this.courses[0]
      return
    }
    this.selectedCourse = this.courses[0]
  }
}
