import { IMentorData, ISalaryVariables } from '@/store/salaryStore'
import { formatTime } from './analytics'
import { showError } from './notifications'

type Period = {
  value: string
  dateStart: string
  dateEnd: string
  answerTime: string
  answerPlan: string
  coef: string
  homeworksApproved: number
  price: string
}

type CoefCalculation = {
  value: string
  coef: string
  plan: number
  price: string
  rating: number
}

export type SalaryCalculation = {
  totalString: string
  total: number
  periods: Period[]
  telegram: CoefCalculation | null
  feedback?: CoefCalculation
}

export function calculateSalary(
  mentorData: IMentorData,
  variables: ISalaryVariables,
): SalaryCalculation {
  const periods: Period[] = []
  let total = 0

  // Периоды
  for (const period of mentorData.periods) {
    let k = 100 - (100 / period.speed) * period.answerTime
    k = Math.trunc(k / mentorData.step) * mentorData.stepPrice

    const periodValue = Math.round(
      mentorData.price * period.homeworksApproved +
        (mentorData.price * period.homeworksApproved * k) / 100,
    )
    k = Math.trunc(k)
    total += periodValue
    periods.push({
      value: getPriceString(periodValue, mentorData.currencySymbol),
      dateStart: new Date(period.dateStart).toLocaleDateString('ru', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }),
      dateEnd: new Date(period.dateEnd).toLocaleDateString('ru', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }),
      answerTime: formatTime(period.answerTime),
      answerPlan: formatTime(period.speed),

      coef: k >= 0 ? `+${k}%` : `${k}%`,
      homeworksApproved: period.homeworksApproved,
      price: getPriceString(mentorData.price, mentorData.currencySymbol),
    })
  }

  // Telegram
  let telegram: CoefCalculation | null = null

  if (mentorData.telegram && mentorData.telegram !== 0) {
    if (
      !variables.telegram.telegramPoints ||
      !variables.telegram.telegramPriceRub ||
      !variables.telegram.telegramPriceUah ||
      !variables.telegram.telegramStep ||
      !variables.telegram.telegramStepCoef
    ) {
      showError('Для курса не заданы настройки телеграм')
      throw new Error('Для курса не заданы настройки телеграм')
    }

    let telegramDiff =
      (mentorData.telegram || 0) - variables.telegram.telegramPoints
    if (telegramDiff < 0) {
      telegramDiff -= variables.telegram.telegramStep
    }
    let telegramCoef =
      Math.trunc(telegramDiff / variables.telegram.telegramStep) *
      variables.telegram.telegramStepCoef

    const telegramPrice =
      mentorData.currency === 'rub'
        ? variables.telegram.telegramPriceRub
        : variables.telegram.telegramPriceUah

    const telegramValue = Math.round(
      telegramPrice + telegramPrice * telegramCoef,
    )

    total += telegramValue

    telegram = {
      value: getPriceString(telegramValue, mentorData.currencySymbol),
      coef:
        telegramCoef >= 0
          ? `+${Math.trunc(telegramCoef * 100)}%`
          : `${Math.trunc(telegramCoef * 100)}%`,
      plan: variables.telegram.telegramPoints,
      price: getPriceString(telegramPrice, mentorData.currencySymbol),
      rating: mentorData.telegram!,
    }
  }

  let feedback: CoefCalculation | undefined = undefined

  if (mentorData.averageRating) {
    // Фидбэк
    let feedbackDiff =
      mentorData.averageRating - variables.feedback.feedbackPoints
    if (feedbackDiff < 0) {
      feedbackDiff -= variables.feedback.feedbackStep
    }
    let feedbackCoef =
      Math.trunc(feedbackDiff / variables.feedback.feedbackStep) *
      variables.feedback.feedbackStepCoef

    const feedbackPrice =
      mentorData.currency === 'rub'
        ? variables.feedback.feedbackPriceRub
        : variables.feedback.feedbackPriceUah

    const feedbackValue = Math.round(
      feedbackPrice + feedbackPrice * feedbackCoef,
    )

    total += feedbackValue

    feedback = {
      value: getPriceString(feedbackValue, mentorData.currencySymbol),
      coef:
        feedbackCoef >= 0
          ? `+${Math.trunc(feedbackCoef * 100)}%`
          : `${Math.trunc(feedbackCoef * 100)}%`,
      plan: variables.feedback.feedbackPoints,
      price: getPriceString(feedbackPrice, mentorData.currencySymbol),
      rating: mentorData.averageRating,
    }
  }

  return {
    totalString: getPriceString(total, mentorData.currencySymbol),
    total,
    periods,
    telegram,
    feedback,
  }
}

function getPriceString(value: number, currency: '₽' | '₴') {
  return `${value}${currency}`
}
