<template>
  <b-modal
    id="vacation-create"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
    ok-title="Добавить"
    :ok-disabled="!okEnabled"
    centered
    button-width="w-100"
    title="Выберите даты отпуска"
    @hidden="clearForm"
    @ok="handleOk"
  >
    <flat-pickr
      v-model="rangeDate"
      :config="{
        mode: 'range',
        locale: 'ru',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd M Y',
      }"
      class="form-control"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  components: {
    BModal,
    flatPickr,
  },
  data: () => ({
    rangeDate: null,
    promiceResolve: null,
  }),
  computed: {
    okEnabled() {
      return this.rangeDate?.split(' — ').length === 2
    },
  },
  methods: {
    clearForm() {
      this.rangeDate = null
    },
    handleOk() {
      const dates = this.rangeDate.split(' — ')

      if (this.promiceResolve) {
        this.promiceResolve(dates)
        this.promiceResolve = null
      }
    },
    show() {
      this.$bvModal.show('vacation-create')

      return new Promise(resolve => (this.promiceResolve = resolve))
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>