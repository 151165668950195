



























import CreatePaymentModal from '@/components/salary/CreatePaymentModal.vue'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import WayupTable from '@/components/WayupTable'
import { Column } from '@/components/WayupTable/types'
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { IMentorData } from '@/store/salaryStore'
import { salaryStore } from '@/store'
import { formatTime } from '@/helpers/analytics'
import { calculateSalary, SalaryCalculation } from '@/helpers/salary'
import { userStore } from '@/store'

@Component({ components: { WayupTable, UserCell, CreatePaymentModal } })
export default class SalaryCalculationTable extends Vue {
  @Ref() readonly createPaymentModal!: {
    show: (calculation: SalaryCalculation, data: IMentorData) => void
  }

  columns: Column<IMentorData>[] = [
    {
      field: 'mentor',
      title: 'Ментор',
    },
    {
      field: 'toPay',
      title: 'К оплате',
      centered: true,
    },
    {
      field: 'price',
      title: 'Ставка за дз',
      centered: true,
      formatter: (value: number, row: IMentorData) =>
        `${value}${row.currencySymbol}`,
    },
    {
      field: 'averageRating',
      title: 'Средняя оценка',
      centered: true,
    },
    {
      field: 'telegram',
      title: 'Оценка telegram',
      type: 'number',
      editable: { inputEvent: this.onTelegramInput },
      centered: true,
    },
    {
      field: 'studentsCount',
      title: 'Студентов',
      centered: true,
    },
    {
      field: 'homeworksApproved',
      title: 'Принято дз',
      centered: true,
    },
    {
      field: 'homeworksRejected',
      title: 'Отклонено дз',
      centered: true,
    },
    {
      field: 'videoreviews',
      title: 'Видеоразборов',
      centered: true,
    },
    {
      field: 'answerTime',
      title: '~Время ответа',
      centered: true,
      noWrap: true,
      formatter: (value: number) => formatTime(value),
    },
    {
      field: 'approveTime',
      title: '~Время принятия дз',
      centered: true,
      noWrap: true,
      formatter: (value: number) => formatTime(value),
    },
    {
      field: 'averageRejected',
      title: '~Правок',
      centered: true,
    },
    {
      field: 'studentMessagesCount',
      title: 'Сообщений',
      centered: true,
    },
    {
      field: 'mentorMessagesCount',
      title: 'Ответов',
      centered: true,
    },
  ]

  get salaryTable() {
    return salaryStore.salaryTable
  }

  get createPaymentAvailiable() {
    return userStore.user?.group.permissions['admin.salary.settings']
  }

  @Watch('salaryTable')
  onSalaryTableChanged() {
    for (const row of this.salaryTable) {
      const calculation = calculateSalary(row, salaryStore.variables!)

      row.toPay = calculation.totalString
    }
  }

  onTelegramInput(value: number, row: IMentorData) {
    if (value < 0 || isNaN(value)) {
      return false
    }

    row.telegram = parseInt(value as any)

    try {
      const calculation = calculateSalary(row, salaryStore.variables!)
      row.toPay = calculation.totalString
    } catch (error) {
      return false
    }
  }

  showCalculation(row: IMentorData) {
    if (!this.createPaymentAvailiable) {
      return
    }

    const calculation = calculateSalary(row, salaryStore.variables!)
    this.createPaymentModal.show(calculation, row)
  }
}
