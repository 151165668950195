









































































































































import { Vue, Component } from 'vue-property-decorator'
import WayupSelect from '@/components/WayupSelect.vue'
import { IStream, ICourse } from '@/store/salaryStore'
import { userStore, salaryStore } from '@/store'

@Component({ components: { WayupSelect } })
export default class SalaryFilters extends Vue {
  dateFormat = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short',
  }

  get settingsAvailiable() {
    return userStore.user?.group.permissions['admin.salary.settings']
  }

  get courses() {
    return salaryStore.courses
  }
  get streams() {
    return salaryStore.streams
  }
  get selectedCourse() {
    return salaryStore.selectedCourse
  }
  set selectedCourse(course: ICourse | null) {
    salaryStore.setCourseFilter(course)
  }
  get selectedStream() {
    return salaryStore.selectedStream
  }
  set selectedStream(stream: IStream | null) {
    salaryStore.setStreamFilter(stream)
  }
  get dateStart() {
    return salaryStore.dateStart
  }
  set dateStart(date: string | null) {
    salaryStore.setDateStart(date)
  }
  get dateEnd() {
    return salaryStore.dateEnd
  }
  set dateEnd(date: string | null) {
    salaryStore.setDateEnd(date)
  }
  get dateError() {
    return salaryStore.dateError
  }
  get formattedDate() {
    return (date: string) => {
      return new Date(date).toLocaleDateString('ru', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
  }

  async updateFilter() {
    await salaryStore.fetchData()
  }

  formatDate(date: Date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - offset * 60 * 1000)
    return date.toISOString().split('T')[0]
  }

  selectToday() {
    this.dateStart = this.formatDate(new Date())
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }

  selectYestarday() {
    const date = new Date()
    date.setDate(date.getDate() - 1)

    this.dateStart = this.formatDate(date)
    this.dateEnd = this.formatDate(date)
    this.updateFilter()
  }

  selectWeek() {
    const date = new Date()
    date.setDate(date.getDate() - 6)

    this.dateStart = this.formatDate(date)
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }

  selectMonth() {
    const date = new Date()
    date.setDate(date.getDate() - 29)

    this.dateStart = this.formatDate(date)
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }

  selectThisMonth() {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)

    this.dateStart = this.formatDate(firstDay)
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }

  selectPastMonth() {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)

    this.dateStart = this.formatDate(firstDay)
    this.dateEnd = this.formatDate(lastDay)
    this.updateFilter()
  }

  selectYear() {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), 0, 1)

    this.dateStart = this.formatDate(firstDay)
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }

  selectQuarter() {
    const date = new Date()
    const month = date.getMonth()
    let firstQuarterMonth = 0

    if (month > 2) {
      firstQuarterMonth = 3
    }
    if (month > 5) {
      firstQuarterMonth = 6
    }
    if (month > 8) {
      firstQuarterMonth = 9
    }

    const firstDay = new Date(date.getFullYear(), firstQuarterMonth, 1)

    this.dateStart = this.formatDate(firstDay)
    this.dateEnd = this.formatDate(new Date())
    this.updateFilter()
  }
}
