<template>
  <div>
    <h5>Отпуска</h5>
    <app-collapse type="default">
      <app-collapse-item
        v-for="mentor of mentors"
        :key="mentor.id"
        :title="mentor.fullName"
        :isVisible="true"
      >
        <template slot="header">
          <div class="d-flex align-items-center">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar :src="mentor.avatar" />
              </template>
              <span>
                {{ mentor.fullName }}
              </span>
            </b-media>
            <b-button
              @click.stop="createVacation(mentor.id)"
              variant="flat-primary"
              class="p-50 ml-50"
            >
              <feather-icon size="20" icon="PlusIcon" />
            </b-button>
          </div>
        </template>

        <div
          v-for="vacation of mentor.vacations"
          :key="vacation.id"
          class="my-50 d-flex align-items-center"
        >
          <div>
            {{
              `${formatDate(vacation.dateStart)} - ${formatDate(
                vacation.dateEnd,
              )}`
            }}
          </div>
          <b-button
            @click="deleteVacation(vacation.id)"
            variant="flat-danger"
            class="p-25 ml-25"
          >
            <feather-icon size="20" icon="XIcon" />
          </b-button>
        </div>
      </app-collapse-item>
    </app-collapse>
    <CreateVacationModal ref="createVacationModal" />
  </div>
</template>

<script>
import CreateVacationModal from '@/components/salary/settingsModal/CreateVacationModal.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BMedia, BAvatar, BButton } from 'bootstrap-vue'
import { showError } from '@/helpers/notifications'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BButton,
    CreateVacationModal,
  },
  data: () => ({ mentors: [] }),
  computed: {
    ...mapGetters({
      course: 'salarySettings/currentCourse',
    }),
  },
  watch: {
    course: async function() {
      await this.fetchData()
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$axios.get(
          `v1/salary/vacations?course=${this.course.id}`,
        )
        this.mentors = response.data.data

        for (const mentor of this.mentors) {
          this.sortVacations(mentor.vacations)
        }
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при загрузке отпусков')
      }
    },
    sortVacations(vacations) {
      vacations.sort(
        (v1, v2) => new Date(v1.dateStart) - new Date(v2.dateStart),
      )
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    async createVacation(mentorId) {
      const dates = await this.$refs.createVacationModal.show()

      if (dates) {
        try {
          const response = await this.$axios.post('v1/salary/vacations', {
            mentor: mentorId,
            course: this.course.id,
            dateStart: dates[0],
            dateEnd: dates[1],
          })

          const mentor = this.mentors.find(m => m.id === mentorId)

          mentor.vacations.push(response.data.vacation)

          this.sortVacations(mentor.vacations)
        } catch (error) {
          console.error(error)
          showError('При добавлении отпуска произошла ошибка')
        }
      }
    },
    async deleteVacation(id) {
      const result = await this.$bvModal.msgBoxConfirm('Подтвердите удаление', {
        cancelVariant: 'outline-secondary',
        okVariant: 'danger',
        centered: true,
        cancelTitle: 'Отмена',
        okTitle: 'Удалить',
      })

      if (!result) {
        return
      }

      try {
        await this.$axios.delete(`v1/salary/vacations/${id}`)
        const mentor = this.mentors.find(m =>
          m.vacations.some(v => v.id === id),
        )
        mentor.vacations = mentor.vacations.filter(v => v.id !== id)
      } catch (error) {
        console.error(error)
        showError('При удалении отпуска произошла ошибка')
      }
    },
  },
}
</script>
