<template>
  <div>
    <h5>Способы оплаты</h5>
    <form @submit.prevent="addPaymentType" class="d-flex mb-1">
      <b-form-input
        v-model="title"
        placeholder="название"
        class="payment-type-input"
      ></b-form-input>
      <b-button
        type="submit"
        :disabled="title.trim() === ''"
        variant="outline-primary"
        class="ml-50"
      >
        Добавить
      </b-button>
    </form>
    <div class="d-flex flex-wrap">
      <div
        v-for="method in paymentMethods"
        :key="method.id"
        class="mr-1 border rounded pl-50"
      >
        <span>
          {{ method.title }}
        </span>
        <b-button
          @click="deletePaymentMethod(method.id)"
          class="p-25"
          variant="flat-danger"
        >
          <feather-icon size="20" icon="XIcon" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BFormInput,
  },
  data: () => ({
    title: '',
  }),
  computed: {
    ...mapGetters({ paymentMethods: 'salarySettings/paymentMethods' }),
  },
  async mounted() {
    this.$store.dispatch('salarySettings/fetchPaymentMethods')
  },
  methods: {
    async addPaymentType() {
      if (this.title.trim() === '') {
        return
      }

      if (
        this.$store.dispatch('salarySettings/addPaymentType', this.title.trim())
      ) {
        this.title = ''
      }
    },
    async deletePaymentMethod(id) {
      const result = await this.$bvModal.msgBoxConfirm('Подтвердите удаление', {
        cancelVariant: 'outline-secondary',
        okVariant: 'danger',
        centered: true,
        cancelTitle: 'Отмена',
        okTitle: 'Удалить',
      })

      if (!result) {
        return
      }

      try {
        this.$store.dispatch('salarySettings/deletePaymentMethod', id)
      } catch (error) {
        console.error(error)
        showError('При удалении метода оплаты произошла ошибка')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-type-input {
  max-width: 250px;
}
</style>
