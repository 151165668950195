<template>
  <b-modal
    id="create-payment-modal"
    :title="title"
    centered
    hide-footer
    scrollable
    no-close-on-backdrop
    size="lg"
    @hidden="reset"
  >
    <CalculationView v-bind="calculationData" class="mb-2" />

    <span>Дополнительные выплаты</span>
    <div class="mb-2">
      <vue-good-table
        :rows="additionalPayments"
        :columns="columns"
        class="compact-good-table additional-payments-table my-50"
      >
        <div slot="emptystate">Нет выплат</div>
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'value'">
            <BFormInput
              v-model="props.row.value"
              @input="handleInput(props.row, props.column.field)"
              type="number"
              :state="props.row.valueState"
              maxlength="6"
            />
          </div>

          <div v-else-if="props.column.field === 'comment'">
            <BFormInput
              v-model="props.row.comment"
              @input="handleInput(props.row, props.column.field)"
              type="text"
              :state="props.row.commentState"
            />
          </div>
          <div v-else>
            <BButton
              @click="deletePayment(props.row)"
              variant="danger"
              class="delete-btn"
            >
              <feather-icon icon="Trash2Icon" />
            </BButton>
          </div>
        </template>
      </vue-good-table>
      <BButton @click="addPayment" variant="outline-primary">Добавить</BButton>
    </div>
    <span>Комментарий</span>
    <BFormTextarea class="mb-2" v-model="comment" />

    <span>Тип оплаты</span>
    <div class="mb-2 d-flex">
      <wayup-select
        v-model="paymentType"
        :options="paymentTypes(paymentType)"
        :appendToBody="true"
        :clearable="false"
        :compareById="true"
        label="title"
        placeholder="Тип оплаты"
      />
    </div>
    <div class="d-flex">
      <BButton variant="primary" class="mr-1" @click="createPayment">
        Произвести выплату {{ totalValue }}
      </BButton>
      <BButton
        variant="outline-danger"
        @click="$bvModal.hide('create-payment-modal')"
      >
        Отмена
      </BButton>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal,
  BBadge,
  BPopover,
  BFormTextarea,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { showError } from '@/helpers/notifications'
import WayupSelect from '@/components/WayupSelect.vue'
import CalculationView from '@/components/salary/CalculationView.vue'
import { salaryStore } from '@/store'

export default {
  components: {
    BModal,
    BBadge,
    BPopover,
    BFormTextarea,
    BButton,
    VueGoodTable,
    BFormInput,
    WayupSelect,
    CalculationView,
  },
  data: () => ({
    calculationData: null,
    periods: [],
    telegram: null,
    feedback: null,
    total: 0,
    data: null,
    comment: '',
    totalString: '',
    title: 'Выплата наставнику',
    columns: [
      {
        field: 'value',
        label: 'Сумма',
        sortable: false,
        tdClass: 'input-cell value',
      },
      {
        field: 'comment',
        label: 'Комментарий',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'buttons',
        label: '',
        sortable: false,
        tdClass: 'input-cell value',
      },
    ],
    additionalPayments: [],
    paymentMethods: [],
    paymentType: null,
  }),
  computed: {
    ...mapGetters({
      variables: 'salary/variables',
    }),
    paymentTypes() {
      return paymentType => {
        if (paymentType?.id === -1 && paymentType) {
          return [paymentType, ...this.paymentMethods]
        }
        return this.paymentMethods
      }
    },
    totalValue() {
      if (!this.data) {
        return 0
      }

      let text = this.formatPrice(this.total)
      if (this.additionalPayments.length) {
        let additionalpaymentsValue = 0
        for (const payment of this.additionalPayments) {
          if (payment.value > 0) {
            additionalpaymentsValue += +payment.value
          }
        }

        text = `${text} + ${this.formatPrice(additionalpaymentsValue)}`
      }

      return text
    },
  },
  async mounted() {
    try {
      const response = await this.$axios.get('v1/salary/payment-methods')
      this.paymentMethods = response.data.paymentMethods
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при получении спопсобов платежей')
    }
  },
  methods: {
    formatPrice(value) {
      if (!this.data) {
        return 0
      }

      return this.data.currency === 'rub' ? `${value}₽` : `₴${value}`
    },
    show(calculationData, data) {
      delete data.vgt_id
      delete data.originalIndex
      this.$bvModal.show('create-payment-modal')
      this.calculationData = calculationData
      this.periods = calculationData.periods
      this.total = calculationData.total
      this.data = data

      this.paymentType = this.paymentMethods.find(
        t => t.title === data.paymentType,
      ) || { title: data.paymentType, id: -1 }

      this.title = `Выплата наставнику ${data.mentor.name} за период ${
        this.periods[0].dateStart
      } - ${this.periods[this.periods.length - 1].dateEnd}`
    },
    addPayment() {
      if (this.additionalPayments.length === 0) {
        this.additionalPayments.push({
          value: this.data.mastermindPrice,
          comment: 'Мастермайнды',
          valueState: undefined,
          commentState: undefined,
        })
      } else {
        this.additionalPayments.push({
          value: '',
          comment: '',
          valueState: false,
          commentState: false,
        })
      }
    },
    deletePayment(row) {
      this.additionalPayments.splice(row.originalIndex, 1)
    },
    handleInput(row, field) {
      const payment = this.additionalPayments[row.originalIndex]
      payment[field] = row[field]

      if (field === 'value') {
        if (parseInt(payment.value) <= 0 || !payment.value) {
          payment.valueState = false
        } else {
          payment.valueState = undefined
        }
      } else {
        if (!payment.comment) {
          payment.commentState = false
        } else {
          payment.commentState = undefined
        }
      }
    },
    reset() {
      this.periods = []
      this.telegram = null
      this.feedback = null
      this.data = null
      this.total = 0
      this.title = 'Выплата наставнику'
      this.additionalPayments = []
      this.comment = ''
      this.totalString = ''
      this.paymentType = null
    },
    async createPayment() {
      if (
        this.additionalPayments.some(
          p => p.valueState === false || p.commentState === false,
        )
      ) {
        return showError('Заполните все дополнительные платежи')
      }

      const result = await salaryStore.addPayment({
        data: this.data,
        calculationData: this.calculationData,
        comment: this.comment,
        bonuses: this.additionalPayments.map(p => ({
          amount: p.value,
          comment: p.comment,
        })),
        paymentMethod: this.paymentType.title,
        amount: this.total,
      })

      if (result) {
        this.$bvModal.hide('create-payment-modal')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.additional-payments-table {
  .input-cell {
    padding: 0 !important;

    &.value {
      width: 100px;
    }
  }

  input {
    height: 100%;
    border-radius: 0;
    min-width: 70px;
    border-color: transparent;

    &.is-invalid {
      border-color: $danger;
    }
  }
}
</style>

<style lang="scss" scoped>
.delete-btn {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}
</style>
