<template>
  <b-modal
    id="payment-stats-modal"
    :title="title"
    centered
    hide-footer
    scrollable
    size="xl"
    @hidden="reset"
  >
    <CalculationView v-bind="calculationData" class="mb-2" />

    <vue-good-table
      :rows="rows"
      :columns="columns"
      class="compact-good-table salary-calculation-table"
    >
      <div slot="emptystate">Нет данных</div>
      <template slot="table-row" slot-scope="props">
        <div
          v-if="props.column.field == 'mentor'"
          class="d-flex flex-column p-25"
        >
          <b-media
            v-if="props.row.mentor"
            vertical-align="center"
            class="d-flex align-items-center"
          >
            <template #aside>
              <b-avatar :src="props.row.mentor.avatar" />
            </template>
            <span>
              {{ props.row.mentor.name }}
            </span>
          </b-media>
        </div>

        <div
          v-else-if="
            props.column.field === 'answerTime' ||
            props.column.field === 'approveTime'
          "
          class="d-flex align-items-center justify-content-center text-nowrap"
        >
          {{ formatTime(props.formattedRow[props.column.field]) }}
        </div>

        <div v-else class="d-flex align-items-center justify-content-center">
          <div>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-modal>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { BAspect, BMedia, BAvatar } from 'bootstrap-vue'
import { formatTime } from '@/helpers/analytics'
import { formatDate } from '@/helpers/formatters'
import CalculationView from '@/components/salary/CalculationView.vue'

export default {
  components: { VueGoodTable, BAspect, BMedia, BAvatar, CalculationView },
  data: () => ({
    title: '',
    calculationData: null,
    rows: [],
    columns: [
      { field: 'mentor', label: 'Ментор', sortable: false },
      {
        field: 'toPay',
        label: 'К оплате',
        sortable: false,
        tdClass: 'input-cell',
      },
      { field: 'price', label: 'Ставка за дз', sortable: false },
      { field: 'averageRating', label: 'Средняя оценка', sortable: false },
      {
        field: 'telegram',
        label: 'Оценка telegram',
        sortable: false,
        tdClass: 'input-cell',
      },
      { field: 'studentsCount', label: 'Студентов', sortable: false },
      { field: 'homeworksApproved', label: 'Принято дз', sortable: false },
      { field: 'homeworksRejected', label: 'Отклонено дз', sortable: false },
      { field: 'videoreviews', label: 'Видеоразборов', sortable: false },
      {
        field: 'answerTime',
        label: '~Время ответа',
        sortable: false,
      },
      {
        field: 'approveTime',
        label: '~Время принятия дз',
        sortable: false,
      },
      {
        field: 'averageRejected',
        label: '~Правок',
        sortable: false,
      },
      {
        field: 'studentMessagesCount',
        label: 'Сообщений',
        sortable: false,
      },
      {
        field: 'mentorMessagesCount',
        label: 'Ответов',
        sortable: false,
      },
    ],
  }),
  methods: {
    formatTime,
    show(data) {
      const parsed = JSON.parse(data)
      const row = parsed.data
      this.calculationData = parsed.calculationData

      this.rows.push(row)
      this.$bvModal.show('payment-stats-modal')

      const dateStart = formatDate(row.periods[0].dateStart)
      const dateEnd = formatDate(row.periods[row.periods.length - 1].dateEnd)

      this.title = `Выплата за ${dateStart} - ${dateEnd}`
    },
    reset() {
      this.rows = []
    },
  },
}
</script>