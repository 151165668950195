<template>
  <b-modal
    id="salary-settings-modal"
    title="Настройки расчета ЗП"
    centered
    hide-footer
    scrollable
    no-close-on-backdrop
    size="xl"
    @hidden="updateTable"
  >
    <Formula class="mb-2" />
    <CourseSelector class="mb-2" />
    <SpeedsTable class="mb-2" />
    <VariablesTable class="mb-2" />
    <VacationList class="mb-2" />
    <PaymentTypes />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import PaymentTypes from '@/components/salary/settingsModal/PaymentTypes.vue'
import SpeedsTable from '@/components/salary/settingsModal/SpeedsTable.vue'
import CourseSelector from '@/components/salary/settingsModal/CourseSelector.vue'
import VariablesTable from '@/components/salary/settingsModal/VariablesTable.vue'
import VacationList from '@/components/salary/settingsModal/VacationList.vue'
import Formula from '@/components/salary/settingsModal/Formula.vue'
import WayupSelect from '@/components/WayupSelect.vue'
import { salaryStore } from '@/store'

export default {
  components: {
    BModal,
    PaymentTypes,
    WayupSelect,
    SpeedsTable,
    CourseSelector,
    VariablesTable,
    VacationList,
    Formula,
  },
  methods: {
    async updateTable() {
      await salaryStore.fetchData()
    },
  },
}
</script>
