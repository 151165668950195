<template>
  <div>
    <h5>Фидбек / Telegram</h5>
    <vue-good-table
      :rows="variables"
      :columns="columns"
      :isLoading="pending"
      class="compact-good-table salary-variables-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field !== 'title'">
          <b-form-input
            v-model="props.row[props.column.field]"
            type="number"
            class="input"
            :state="props.row[`${props.column.field}State`]"
            @blur="updateValue(props.row, props.column.field)"
            @keydown="if ($event.key === 'Enter') $event.target.blur()"
          />
        </div>

        <div v-else class="d-flex align-items-center justify-content-center">
          <div>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BModal,
  BAspect,
  BMedia,
  BImg,
  BAvatar,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import WayupSelect from '@/components/WayupSelect.vue'
import { showError, showMessage } from '@/helpers/notifications'
import { mapGetters } from 'vuex'

export default {
  components: {
    BModal,
    BAspect,
    BMedia,
    BImg,
    BAvatar,
    VueGoodTable,
    BFormInput,
    WayupSelect,
  },
  data: () => ({
    pending: false,
    variables: [],
    columns: [
      { field: 'title', label: '', sortable: false },
      {
        field: 'points',
        label: 'Баллы',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'step',
        label: 'Шаг очков',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'stepCoef',
        label: 'Шаг коэф',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'priceRub',
        label: 'Сумма (₽)',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'priceUah',
        label: 'Сумма (₴)',
        sortable: false,
        tdClass: 'input-cell',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      course: 'salarySettings/currentCourse',
    }),
  },
  watch: {
    course: async function() {
      await this.fetchData()
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.pending = true
      try {
        const response = await this.$axios.get(
          `v1/salary/course/variables/${this.course.id}`,
        )
        this.fillData(response.data.variables)
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при загрузке переменных')
      } finally {
        this.pending = false
      }
    },
    fillData(variables) {
      this.variables = [
        {
          title: 'Telegram',
          value: 'telegram',
          points: 0,
          step: 0,
          stepCoef: 0,
          priceRub: 0,
          priceUah: 0,
          pointsState: null,
          stepState: null,
          stepCoefState: null,
          priceRubState: null,
          priceUahState: null,
        },
        {
          title: 'Фидбэк',
          value: 'feedback',
          points: 0,
          step: 0,
          stepCoef: 0,
          priceRub: 0,
          priceUah: 0,
          pointsState: null,
          stepState: null,
          stepCoefState: null,
          priceRubState: null,
          priceUahState: null,
        },
      ]

      if (!variables) {
        return
      }
      if (variables.telegram) {
        this.variables[0].points = variables.telegram.telegramPoints || 0
        this.variables[0].step = variables.telegram.telegramStep || 0
        this.variables[0].stepCoef = variables.telegram.telegramStepCoef || 0
        this.variables[0].priceRub = variables.telegram.telegramPriceRub || 0
        this.variables[0].priceUah = variables.telegram.telegramPriceUah || 0
      }
      if (variables.feedback) {
        this.variables[1].points = variables.feedback.feedbackPoints || 0
        this.variables[1].step = variables.feedback.feedbackStep || 0
        this.variables[1].stepCoef = variables.feedback.feedbackStepCoef || 0
        this.variables[1].priceRub = variables.feedback.feedbackPriceRub || 0
        this.variables[1].priceUah = variables.feedback.feedbackPriceUah || 0
      }
    },
    async updateValue(row, field) {
      const variables = this.variables.find(v => v.value === row.value)

      if (variables[field] === row[field]) {
        return
      }

      if (row[field] < 0) {
        row[`${field}State`] = false
        return showError('Значение не может быть отрицательным')
      }

      try {
        const data = {
          telegram: {},
          feedback: {},
        }
        data[row.value][field] = row[field]

        await this.$axios.post(
          `v1/salary/course/variables/${this.course.id}`,
          data,
        )
        row[`${field}State`] = null
        variables[field] = row[field]
        showMessage('Данные обновлены')
      } catch (error) {
        console.error(error)
        row[`${field}State`] = false
        showError('При обнавлении данных произошла ошибка')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.salary-variables-table {
  .vgt-table {
    width: initial !important;
  }

  th {
    padding: 5px !important;
  }

  .input-cell {
    padding: 0 !important;
    max-width: 100px;
  }

  .input {
    height: 100%;
    border-radius: 0;
    min-width: 70px;

    &:not(:focus) {
      border-color: transparent;
    }

    &.is-invalid {
      border-color: $danger;
    }
  }
}
</style>
