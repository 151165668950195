<template>
  <div>
    <b-badge variant="info">Ставка за дз</b-badge>
    <feather-icon size="16" icon="XIcon" class="mx-25" />
    <b-badge variant="success">Принято дз</b-badge>
    <feather-icon size="16" icon="XIcon" class="mx-25" />
    <b-badge variant="primary">Коэф-т скорости</b-badge>
    <feather-icon size="16" icon="PlusIcon" class="mx-25" />
    <b-badge variant="warning">Фидбек</b-badge>
    <feather-icon size="16" icon="PlusIcon" class="mx-25" />
    <b-badge variant="dark">Telegram</b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: { BBadge },
}
</script>