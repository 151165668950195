var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wayup-select',{staticClass:"select mb-2",attrs:{"placeholder":"выберите курс","emptyFilterMessage":"нет подходящих курсов","options":_vm.courses,"label":"title","clearable":false,"appendToBody":true},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var imageUrl = ref.imageUrl;
var hex = ref.hex;
return [_c('div',{staticClass:"d-flex align-items-center course-option"},[_c('div',{staticClass:"\n          image-wrapper\n          d-flex\n          align-items-center\n          justify-content-center\n          mr-50\n        ",style:(("background: " + hex))},[_c('img',{attrs:{"src":imageUrl,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")])])]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
var imageUrl = ref.imageUrl;
var hex = ref.hex;
return [_c('div',{staticClass:"d-flex align-items-center course-option selected"},[_c('div',{staticClass:"\n          image-wrapper\n          d-flex\n          align-items-center\n          justify-content-center\n          mr-50\n        ",style:(("background: " + hex))},[_c('img',{attrs:{"src":imageUrl,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")])])]}}]),model:{value:(_vm.selectedCourse),callback:function ($$v) {_vm.selectedCourse=$$v},expression:"selectedCourse"}})}
var staticRenderFns = []

export { render, staticRenderFns }