<template>
  <div>
    <h5>Нормы скорости для наставников</h5>
    <vue-good-table
      :rows="mentors"
      :columns="columns"
      :isLoading="pending"
      class="compact-good-table speeds-table"
    >
      <div slot="emptystate">Не найдено менторов</div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'id'">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside>
              <b-avatar :src="props.row.avatar" />
            </template>
            <span>
              {{ props.row.name }}
            </span>
          </b-media>
        </div>

        <div
          v-else-if="!['currency', 'paymentType'].includes(props.column.field)"
        >
          <b-form-input
            v-model="props.row[props.column.field]"
            type="number"
            class="input"
            :state="props.row[`${props.column.field}State`]"
            @blur="updateValue(props.row, props.column.field)"
            @keydown="if ($event.key === 'Enter') $event.target.blur()"
          />
        </div>

        <div v-else-if="props.column.field === 'currency'">
          <wayup-select
            v-model="props.row.currency"
            :options="currencies"
            :appendToBody="true"
            :clearable="false"
            :compareById="true"
            :flat="true"
            placeholder="-"
            class="currency-selector"
            @change="updateCurrency(props.row)"
          />
        </div>

        <div v-else-if="props.column.field === 'paymentType'">
          <wayup-select
            v-model="props.row.paymentType"
            :options="paymentTypes(props.row.paymentType)"
            :appendToBody="true"
            :clearable="false"
            :compareById="true"
            :flat="true"
            label="title"
            placeholder="-"
            class="payment-selector"
            @change="updatePaymentType(props.row)"
          />
        </div>

        <div v-else class="d-flex align-items-center justify-content-center">
          <div>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BModal,
  BAspect,
  BMedia,
  BImg,
  BAvatar,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import WayupSelect from '@/components/WayupSelect.vue'
import { showError, showMessage } from '@/helpers/notifications'
import { mapGetters } from 'vuex'

export default {
  components: {
    BModal,
    BAspect,
    BMedia,
    BImg,
    BAvatar,
    VueGoodTable,
    BFormInput,
    WayupSelect,
  },
  data: () => ({
    pending: false,
    mentors: [],
    columns: [
      { field: 'id', label: 'Наставник', sortable: false },
      {
        field: 'speed1',
        label: 'Скорость 1мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed2',
        label: 'Скорость 2мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed3',
        label: 'Скорость 3мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed4',
        label: 'Скорость 4мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed5',
        label: 'Скорость 5мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed6',
        label: 'Скорость 7мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'speed7',
        label: 'Скорость 6мес, час',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'step',
        label: 'Шаг, %',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'stepPrice',
        label: 'Цена за шаг, %',
        sortable: false,
        tdClass: 'input-cell',
      },
      {
        field: 'price',
        label: 'Стоимость дз',
        sortable: false,
        tdClass: 'input-cell',
      },

      { field: 'currency', label: 'Валюта', sortable: false },
      { field: 'paymentType', label: 'Способ оплаты', sortable: false },
      {
        field: 'mastermindPrice',
        label: 'Стоимость мастермайнда',
        sortable: false,
        tdClass: 'input-cell',
      },
    ],
    currencies: [
      {
        label: '₽',
        id: 'rub',
      },
      {
        label: '₴',
        id: 'uah',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      course: 'salarySettings/currentCourse',
      _paymentTypes: 'salarySettings/paymentMethods',
    }),
    paymentTypes() {
      return paymentType => {
        if (paymentType?.id === -1 && paymentType?.title) {
          return [paymentType, ...this._paymentTypes]
        }
        return this._paymentTypes
      }
    },
  },
  watch: {
    course: async function () {
      await this.fetchData()
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.pending = true
      try {
        const response = await this.$axios.get(
          `v1/salary/speeds/${this.course.id}`,
        )
        this.fillData(response.data.data)
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при загрузке норм скорости наставников')
      } finally {
        this.pending = false
      }
    },
    fillData(data) {
      const mentors = data.mentors
      const speeds = data.speeds

      for (const mentor of mentors) {
        Object.assign(mentor, {
          speed1: null,
          speed2: null,
          speed3: null,
          speed4: null,
          speed5: null,
          step: null,
          stepPrice: null,
          currency: null,
          price: null,
          mastermindPrice: null,
          paymentType: null,
          speed1State: null,
          speed2State: null,
          speed3State: null,
          speed4State: null,
          speed5State: null,
          speed6State: null,
          speed7State: null,
          stepState: null,
          stepPriceState: null,
          currencyState: null,
          priceState: null,
          mastermindPriceState: null,
          paymentTypeState: null,
        })
      }

      for (const speed of speeds) {
        const mentor = mentors.find(m => m.id === speed.mentorId)
        mentor.speed1 = speed.speeds.speed1
        mentor.speed2 = speed.speeds.speed2
        mentor.speed3 = speed.speeds.speed3
        mentor.speed4 = speed.speeds.speed4
        mentor.speed5 = speed.speeds.speed5
        mentor.speed6 = speed.speeds.speed6
        mentor.speed7 = speed.speeds.speed7
        mentor.step = speed.step
        mentor.stepPrice = speed.stepPrice
        mentor.currency =
          this.currencies.find(c => c.id === speed.currency) || null
        mentor.price = speed.price
        mentor.mastermindPrice = speed.mastermindPrice
        mentor.paymentType = this._paymentTypes.find(
          t => t.title === speed.paymentType,
        ) || { title: speed.paymentType, id: -1 }
      }

      this.mentors = mentors
    },
    async updateCurrency(row) {
      const mentor = this.mentors.find(m => m.id === row.id)
      if (mentor.currency === row.currency) {
        return
      }

      try {
        const data = {
          courseId: this.course.id,
          mentorId: mentor.id,
          currency: row.currency.id,
        }

        await this.$axios.post('v1/salary/speeds', data)

        mentor.currency = row.currency
        showMessage('Данные обновлены')
      } catch (error) {
        console.error(error)
        showError('При обнавлении данных произошла ошибка')
      }
    },
    async updatePaymentType(row) {
      const mentor = this.mentors.find(m => m.id === row.id)
      if (mentor.paymentType === row.paymentType) {
        return
      }

      try {
        const data = {
          courseId: this.course.id,
          mentorId: mentor.id,
          paymentType: row.paymentType.title,
        }

        await this.$axios.post('v1/salary/speeds', data)

        mentor.paymentType = row.paymentType
        showMessage('Данные обновлены')
      } catch (error) {
        console.error(error)
        showError('При обнавлении данных произошла ошибка')
      }
    },
    async updateValue(row, field) {
      const mentor = this.mentors.find(m => m.id === row.id)

      if (mentor[field] === row[field]) {
        return
      }

      if (row[field] < 0) {
        row[`${field}State`] = false
        return showError('Значение не может быть отрицательным')
      }

      try {
        const data = {
          courseId: this.course.id,
          mentorId: mentor.id,
        }

        data[field] = row[field]

        await this.$axios.post('v1/salary/speeds', data)
        row[`${field}State`] = null
        mentor[field] = row[field]
        showMessage('Данные обновлены')
      } catch (error) {
        console.error(error)
        row[`${field}State`] = false
        showError('При обнавлении данных произошла ошибка')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.speeds-table {
  th {
    padding: 5px !important;
  }

  .currency-selector {
    max-width: 100px;
    min-width: 100px;
  }

  .input-cell {
    padding: 0 !important;
  }

  .input {
    height: 100%;
    border-radius: 0;
    min-width: 70px;

    &:not(:focus) {
      border-color: transparent;
    }

    &.is-invalid {
      border-color: $danger;
    }
  }
}
</style>
