














import Filters from '@/components/salary/Filters.vue'
import SettingsModal from '@/components/salary/SettingsModal.vue'
import Calculation from '@/components/salary/Calculation.vue'
import Payments from '@/components/salary/Payments.vue'
import SalaryReport from '@/components/salary/Report'

import { Vue, Component } from 'vue-property-decorator'
import { salaryStore, userStore } from '@/store'

@Component({
  components: { Filters, SettingsModal, Calculation, Payments, SalaryReport },
})
export default class Salary extends Vue {
  get pending() {
    return salaryStore.pending
  }

  get isSalaryReportAvailiable() {
    return (
      userStore.user?.id === 1 || //Миша
      userStore.user?.id === 5 || //ИЯ
      userStore.user?.id === 7 || //Анастасия Полищук
      userStore.user?.id === 10 || //Андрей
      userStore.user?.id === 30 || //Анастасия Сысоева
      userStore.user?.id === 47 || //Аркадий
      process.env.VUE_APP_AXIOS_URL.includes('stage')
    )
  }

  async mounted() {
    await salaryStore.fetchFilterData()
  }
}
